export const SET_HOME_IMAGE = 'SET_HOME_IMAGE';
export const SET_HOME_DATA = 'SET_HOME_DATA';

const initialState = {
  homeImageURL: '',
  homeTitle: '',
  homeSubtitle: '',
};

export const setHomeData = homeData => {
  return { type: SET_HOME_DATA, payload: homeData };
};

export const setHomeImage = homeData => {
  return { type: SET_HOME_IMAGE, payload: homeData };
};

export function homeReducer(state = initialState, action) {
  switch (action.type) {
    case SET_HOME_DATA:
      return {
        ...state,
        homeTitle: action.payload.homeTitle,
        homeSubtitle: action.payload.homeSubtitle
      };

    case SET_HOME_IMAGE:
      return {
        ...state,
        homeImageURL: action.payload
      };

    default:
      return state;
  }
}
