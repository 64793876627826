export const SET_CALENDAR = "SET_CALENDAR";
export const SET_CALENDAR_VIEW = "SET_CALENDAR_VIEW";
export const SET_CALENDAR_AGE = "SET_CALENDAR_AGE";
export const SET_CALENDAR_YEAR = "SET_CALENDAR_YEAR";
export const SET_DOWNLOAD = "SET_DOWNLOAD";

const initialState = {
  calendarData: [],
  calendarAge: "adult",
  calendarView: true,
  calendarYear: 2022,
  downloadUrl: "",
};

export const setCalendar = (calendarData) => {
  return { type: SET_CALENDAR, calendarData };
};

export const setView = (view) => {
  return { type: SET_CALENDAR_VIEW, view };
};

export const setAge = (age) => {
  return { type: SET_CALENDAR_AGE, age };
};

export const setYear = (year) => {
  return { type: SET_CALENDAR_YEAR, year };
};

export const setDownload = (url) => {
  return { type: SET_DOWNLOAD, url };
};

export function calendarReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CALENDAR:
      return {
        ...state,
        calendarData: action.calendarData,
      };

    case SET_CALENDAR_VIEW:
      return {
        ...state,
        calendarView: action.view.calendarView,
      };

    case SET_CALENDAR_AGE:
      return {
        ...state,
        calendarAge: action.age.calendarAge,
      };

    case SET_CALENDAR_YEAR:
      return {
        ...state,
        calendarYear: action.year.calendarYear,
      };

    case SET_DOWNLOAD:
      return {
        ...state,
        downloadUrl: action.url,
      };

    default:
      return state;
  }
}
