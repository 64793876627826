import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

const PrivateRoute = ({component: Component, ...rest}) => {
  let generalAuthentication = useSelector(state => get(state, `authReducer.generalAuthentication`, false));
  let loading = useSelector(state => get(state, `authReducer.loading`, true)) 

  return (
    <Route {...rest } render={props =>
      loading ?
        <div></div>
        :
        generalAuthentication ?
          (<Component {...props} />) 
          : 
          (<Redirect to='/login' />)          
    } />
  )
}

export default PrivateRoute;