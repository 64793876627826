const primary = {
  lightest: '#F6F6FA',
  lighter: '#C4C4CD',
  light: '#747480',
  main: '#2e2e38',
  dark: '#000000',
  contrastText: '#fff',
  default: '#FAFAFC',
};

const secondary = {
  lightest: '#fffde7',
  lighter: '#fff59d',
  light: '#fff17c',
  main: '#006400',
  dark: '#b2a100',
  contrastText: '#2e2e38',
};

const error = {
  lightest: '#FADDDC',
  lighter: '#FF4136',
  light: '#E0362C',
  main: '#F52C3C',
  contrastText: '#fff',
};

const tags = {
  thirdPartyCertified: '#E1E9A4',
  supplierCertified: '#D9F4F3',
  pendingFont: '#747480',
  noCoo: '#EBEBEE',
  noCooFont: '#747480',
  font: '#0D7575',
  certified: '#168736',
  disputed: '#B9251C',
  certifiedBg: '#8CE8AD',
  disputedBg: '#FF9A91',
};

const success = {
  lightest: '#FBFEFC',
  lighter: '',
  light: '#57E188',
  main: '#168736',
  contrastText: '#fff',
};

const warning = {
  lightest: '#FF9831',
  lighter: '#FF800A',
  light: '#FF6D00',
  main: '#EB4F00',
  contrastText: '#2e2e38',
};

const background = {
  paper: primary.contrastText,
  default: primary.default,
};

const htmlFontSize = 16;
const fontSize = 14;
const coef = fontSize / 14;
const pxToRem = (size) => `${(size / htmlFontSize) * coef}rem`;

const typography = {
  htmlFontSize,
  fontSize,
  button: {
    fontWeight: 700,
    fontSize: '1rem',
    textTransform: 'none',
    lineHeight: '150%',
    letterSpacing: '0.01em',
  },
  h1: {
    fontSize: `${pxToRem(44)}`,
    fontWeight: 300,
    lineHeight: `${pxToRem(56)}`,
    fontFamily: 'Merriweather',
  },
  h2: {
    fontSize: `${pxToRem(34)}`,
    fontWeight: 300,
    lineHeight: `${pxToRem(44)}`,
    fontFamily: 'Merriweather',
  },
  h3: {
    fontSize: `${pxToRem(32)}`,
    fontWeight: 300,
    lineHeight: `${pxToRem(40)}`,
    fontFamily: 'Merriweather',
  },
  h4: {
    fontSize: `${pxToRem(24)}`,
    fontWeight: 300,
    lineHeight: `${pxToRem(30)}`,
    fontFamily: 'Merriweather',
  },
  h5: {
    fontSize: `${pxToRem(20)}`,
    fontWeight: 300,
    lineHeight: `${pxToRem(28)}`,
    fontFamily: 'Merriweather'
  },
  body1: {
    fontFamily: 'Montserrat',
    fontSize: `${pxToRem(18)}`,
  },
  body2: {
    fontFamily: 'Montserrat',
    fontSize: `${pxToRem(14)}`,
  },
  body3: {
    fontFamily: 'Montserrat',
    fontSize: `${pxToRem(1)}`,
  }
};

export default {
  palette: {
    primary,
    secondary,
    error,
    success,
    warning,
    background,
    tags,
    action: {
      disabled: primary.lighter,
    },
  },
  typography
};
