import React, { useEffect, useState } from 'react';
import get from 'lodash/get';

// Components
import Header from '../components/Header';

// Material UI
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

// Redux
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { fetchAboutData } from '../redux/about';

const useStyles = makeStyles((theme) => ({
  aboutContent: {
    marginRight: '20px',
    marginLeft: '20px',
    padding: '20px',
    paddingBottom: '40px'
  },
  paperLarge: {
    marginTop: '25px',
    marginBottom: '25px',
    width: '80%',
    marginRight: 'auto',
    marginLeft: 'auto'
  },
  paperSmall: {
    marginTop: '10px',
    marginBottom: '10px',
    width: '95%',
    marginRight: 'auto',
    marginLeft: 'auto'
  },
  backdrop: {
    backgroundImage: `url('https://images.unsplash.com/photo-1508172819040-536e3f54970f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80')`,
    minHeight: '1000px',
    backgroundPosition: '0% 75.8%',
    backgroundRepeat: 'repeat-y',
    paddingBottom: '10px'
  },
  title: {
    fontFamily: 'Playfair Display',
  }
}));

const About = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [size, setSize] = useState(props.width);

  const aboutDescription = useSelector(state => get(state, `aboutReducer.aboutDescription`, ''));

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  useEffect(() => {
    setSize(props.width)
  },[props.width])

  useEffect(() => {
    dispatch(fetchAboutData())
  }, [dispatch])

  const populateMultiLineText = (text) => {
    const splitText = text.split('\n');
    return splitText.map((t, index) => (
      <Typography key={index} variant={"body1"} paragraph={true}>
        { t }
      </Typography>
    ))
  }

  return (
    <div className={classes.backdrop}>
      <Header background={true}/>
      <Paper className={['xs', 'sm'].includes(size) ? classes.paperSmall : classes.paperLarge }>
        <Grid container justify="center" className={classes.aboutGrid}>
          <Grid item xs={12} md={10} className={classes.aboutContent}>
            <Typography className={classes.title} variant='h2' paragraph={true} align={'center'} color={'error'}>
              About
            </Typography>
            { aboutDescription && 
              populateMultiLineText(aboutDescription)
            }            
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default withWidth()(About);
