import React from "react";

import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

const ChristmasSwitch = withStyles({
  switchBase: {
    color: "#006400",
    "&$checked": {
      color: "#F52C3C",
    },
  },
  checked: {},
  track: {},
})(Switch);

const TwoWordSwitch = (props) => {
  const mobileView = props.width === "xs";

  const { firstLabel, secondLabel, onChange, checked } = props;

  return (
    <>
      <Typography
        variant={mobileView ? "body2" : "body1"}
        display="inline"
        color="secondary"
      >
        {firstLabel}
      </Typography>
      <ChristmasSwitch onChange={onChange} checked={checked} name="checked" />
      <Typography
        variant={mobileView ? "body2" : "body1"}
        display="inline"
        color="error"
      >
        {secondLabel}
      </Typography>
    </>
  );
};

export default withWidth()(TwoWordSwitch);
