import React from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

// Components
import Header from "../components/Header";
import CalendarArea from "../components/CalendarArea";

const useStyles = makeStyles((theme) => ({
  homeArea: {
    width: "100%",
    backgroundImage: `url('https://images.unsplash.com/photo-1519248708452-2c393f8dc725?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80')`,
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "local",
  },
}));

const Calendar = (props) => {
  const classes = useStyles();
  const topRef = React.createRef();

  return (
    <div ref={topRef} className={classes.homeArea}>
      <Header background={true} />
      <CalendarArea />
    </div>
  );
};

export default withWidth()(Calendar);
