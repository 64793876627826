import { RSAA } from "redux-api-middleware";
import {
  saveGeneralLogin,
  clearGeneralLogin,
  confirmGeneralAuthentication,
} from "./reducers/authReducer";

export const GENERAL_LOGIN_REQUEST = "GENERAL_LOGIN_REQUEST";
export const GENERAL_LOGIN_SUCCESS = "GENERAL_LOGIN_SUCCESS";
export const GENERAL_LOGIN_FAILURE = "GENERAL_LOGIN_FAILURE";

export const CHECK_AUTH_REQUEST = "CHECK_AUTH_REQUEST";
export const CHECK_AUTH_SUCCESS = "CHECK_AUTH_SUCCESS";
export const CHECK_AUTH_FAILURE = "CHECK_AUTH_FAILURE";

export const generalLogin = (password) => ({
  [RSAA]: {
    endpoint: `https://krassicalendar.com/api/auth/generallogin/`,
    method: "POST",
    body: JSON.stringify({ password }),
    headers: { "Content-Type": "application/json" },
    types: [
      GENERAL_LOGIN_REQUEST,
      {
        type: GENERAL_LOGIN_SUCCESS,
        payload: (action, state, res) => res.json(),
      },
      {
        type: GENERAL_LOGIN_FAILURE,
        payload: (action, state, res) => res.json(),
        meta: (action) => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const checkAuth = () => ({
  [RSAA]: {
    endpoint: `https://krassicalendar.com/api/auth/checkAuth/`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": localStorage.token,
    },
    types: [
      CHECK_AUTH_REQUEST,
      {
        type: CHECK_AUTH_SUCCESS,
        payload: (action, state, res) => res.json(),
      },
      {
        type: CHECK_AUTH_FAILURE,
        meta: (action) => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const authMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case GENERAL_LOGIN_SUCCESS:
        dispatch(saveGeneralLogin(action.payload.token));
        break;

      case GENERAL_LOGIN_FAILURE:
        dispatch(clearGeneralLogin(action.payload.errors));
        break;

      case CHECK_AUTH_SUCCESS:
        if (action.payload.generalAuthentication) {
          dispatch(confirmGeneralAuthentication());
        }
        break;

      case CHECK_AUTH_FAILURE:
        dispatch(clearGeneralLogin([]));
        break;

      default:
        break;
    }
  };
