import React from "react";
import { useState, useRef, useEffect } from "react";
import get from "lodash/get";

// Material UI
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";

// Components
import Header from "../components/Header";
import CalendarArea from "../components/CalendarArea";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { fetchCalendar, downloadCalendar } from "../redux/calendar";
import { setView, setAge } from "../redux/reducers/calendarReducer";
import { fetchHomePhoto, fetchHomeData } from "../redux/home";

const useStyles = makeStyles((theme) => ({
  image: {
    width: "auto",
    height: "50vh",
    boxShadow: "5px 5px 5px #ccc",
    webkitBoxShadow: "5px 5px 5px #ccc",
    mozBoxShadow: "5px 5px 5px #ccc",
  },
  imageMobile: {
    width: "100%",
    height: "auto",
    boxShadow: "5px 5px 5px #ccc",
    webkitBoxShadow: "5px 5px 5px #ccc",
    mozBoxShadow: "5px 5px 5px #ccc",
  },
  welcomeText: {
    paddingTop: "20px",
    display: "table-caption",
    captionSide: "bottom",
  },
  homeArea: {
    width: "100%",
    backgroundImage: `url('https://images.unsplash.com/photo-1519248708452-2c393f8dc725?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80')`,
    backgroundAttachment: "local",
  },
  navigationButton: {
    marginTop: "20px",
    marginBottom: "20px",
    "&:hover": {
      background: "none",
    },
  },
  buttonRow: {
    minHeight: "150px",
    zIndex: 10000,
  },
  calendarRow: {
    width: "100%",
    backgroundImage: `url('https://images.unsplash.com/photo-1482242248426-c4e9fe3a559a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80')`,
    minHeight: "1000px",
    backgroundPosition: "10% 0%",
    transform: "scaleX(-1)",
  },
  calendarArea: {
    width: "80%",
    marginRight: "auto",
    marginLeft: "auto",
    paddingBottom: "100px",
    transform: "scaleX(-1)",
  },
  listArea: {
    width: "95%",
    marginRight: "auto",
    marginLeft: "auto",
    transform: "scaleX(-1)",
  },
  calendarBackground: {
    background: "white",
  },
  calendarTitleMobile: {
    fontWeight: "bold",
    padding: "10px",
    textAlign: "center",
    borderBottom: "1px solid rgb(217,217,217)",
    position: "-webkit-sticky" /* Safari */,
    position: "sticky",
    zIndex: 1000,
    top: "99px",
    background: "white",
  },
  calendarTitle: {
    fontWeight: "bold",
    padding: "10px",
    textAlign: "center",
    borderTop: "1px solid rgb(217,217,217)",
    borderRight: "1px solid rgb(217,217,217)",
    borderLeft: "1px solid rgb(217,217,217)",
    position: "-webkit-sticky" /* Safari */,
    position: "sticky",
    zIndex: 1000,
    top: "119px",
    background: "white",
  },
  pictureAndButton: {
    position: "relative",
    minHeight: "845px",
  },
  bottomArea: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "134px",
  },
  bottomAreaMobile: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "99px",
  },
  printButton: {
    textTransform: "none",
  },
}));

const Home = (props) => {
  const classes = useStyles();
  const topRef = React.createRef();
  const bottom = React.createRef();
  const listInnerRef = useRef();

  const mobileView = props.width === "xs" || props.width === "sm";

  const [scrollDown, setScrollDown] = useState(true);
  const [background, setBackground] = useState(false);

  const homeData = useSelector((state) => get(state, `homeReducer`, ""));
  const calendarYear = useSelector((state) =>
    get(state, `calendarReducer.currentYear`, 2022)
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchHomePhoto());
    dispatch(fetchHomeData());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.onscroll = () => {
      if (listInnerRef.current) {
        if (listInnerRef.current.getBoundingClientRect().bottom < 500) {
          setScrollDown(false);
        }
        if (listInnerRef.current.getBoundingClientRect().top > 0) {
          setScrollDown(true);
        }
        if (!mobileView) {
          if (listInnerRef.current.getBoundingClientRect().bottom < 134) {
            setBackground(true);
          } else {
            setBackground(false);
          }
        }
        if (mobileView) {
          if (listInnerRef.current.getBoundingClientRect().bottom < 99) {
            setBackground(true);
          } else {
            setBackground(false);
          }
        }
      }
    };
  }, [background, mobileView]);

  const scrollButtonClick = () => {
    bottom.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div ref={topRef} className={classes.homeArea}>
      <Header background={background} />
      <div ref={listInnerRef} className={classes.pictureAndButton}>
        <Grid container justify="center">
          <Grid item>
            <div
              style={{
                padding: "20px",
                background: "rgb(255, 255, 255, .9)",
                textAlign: "center",
                borderRadius: "5px",
              }}
            >
              <div style={{ display: "table" }}>
                <img
                  alt=""
                  className={mobileView ? classes.imageMobile : classes.image}
                  src={
                    homeData.homeImageURL === ""
                      ? "gray.png"
                      : homeData.homeImageURL
                  }
                />
                <Typography variant="h2" className={classes.welcomeText}>
                  {homeData.homeTitle}
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
        <div>
          <Grid container justify="center">
            <div className={classes.buttonRow}>
              {scrollDown && (
                <Button
                  className={classes.navigationButton}
                  id="button1"
                  onClick={scrollButtonClick}
                  disableRipple
                >
                  <ExpandMoreIcon style={{ fontSize: "80", color: "white" }} />
                </Button>
              )}
            </div>
          </Grid>
        </div>
        <div
          className={mobileView ? classes.bottomAreaMobile : classes.bottomArea}
          ref={bottom}
        ></div>
      </div>
      <CalendarArea />
    </div>
  );
};

export default withWidth()(Home);
