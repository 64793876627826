import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import AcUnitRoundedIcon from '@material-ui/icons/AcUnitRounded';
import MenuIcon from '@material-ui/icons/Menu';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import CameraIcon from '@material-ui/icons/Camera';

const useStyles = makeStyles({
  menuTab: {
    float: 'left',
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  iconButton: {
    width: '100px',
    height: '55px',
    background: 'rgb(255, 255, 255, .9)',
    '&:hover': {
      background: 'rgb(225, 225, 225, .9)',
    },
  },
  list: {
    width: 250,
    background: 'rgb(240, 240, 240)',
    height: '100%',
  },
  fullList: {
    width: 'auto',
  },
  link: {
    textDecoration: 'none',
    color: 'rgb(106, 106, 106)'
  },
  listItemText: {
    fontFamily: 'Montserrat',
    fontWeight: 'bold'
  }
});

export default function TempDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const icons = [
    <HomeIcon />,
    <AcUnitRoundedIcon />,
    <CalendarTodayIcon />,
    <CameraIcon />,
    <MenuBookIcon />,
  ];

  const list = (anchor) => (
    <div
      className={classes.list}
      role='presentation'
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {['Home','About', 'Calendar', 'Memories', 'Guestbook'].map(
          (text, index) => (
            <Link to={`/${text.toLowerCase()}`} className={classes.link} key={index} replace>
              <ListItem button key={text}>
                <ListItemIcon>{icons[index]}</ListItemIcon>
                <ListItemText primary={text} className={classes.listItemText} />
                <Divider />
              </ListItem>
            </Link>
          )
        )}
      </List>
    </div>
  );

  return (
    <div className={classes.menuTab}>
      <React.Fragment key={'left'}>
        <Button
          onClick={toggleDrawer('left', true)}
          className={classes.iconButton}
        >
          <MenuIcon fontSize='large' className={classes.icon} />
        </Button>
        <Drawer
          anchor={'left'}
          open={state['left']}
          onClose={toggleDrawer('left', false)}
        >
          {list('left')}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
