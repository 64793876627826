import { RSAA } from "redux-api-middleware";
import {
  setMemoriesPhotoIds,
  setMemoriesPhotos,
} from "./reducers/memoriesReducer";

export const FETCH_MEMORIES_PHOTOS_REQUEST = "FETCH_MEMORIES_PHOTOS_REQUEST";
export const FETCH_MEMORIES_PHOTOS_SUCCESS = "FETCH_MEMORIES_PHOTOS_SUCCESS";
export const FETCH_MEMORIES_PHOTOS_FAILURE = "FETCH_MEMORIES_PHOTOS_FAILURE";

export const FETCH_PHOTO_REQUEST = "FETCH_PHOTO_REQUEST";
export const FETCH_PHOTO_SUCCESS = "FETCH_PHOTO_SUCCESS";
export const FETCH_PHOTO_FAILURE = "FETCH_PHOTO_FAILURE";

export const fetchMemoriesPhotos = () => ({
  [RSAA]: {
    endpoint: `https://krassicalendar.com/api/photos/ids/memories/`,
    headers: {
      "x-auth-token": localStorage.token,
    },
    method: "GET",
    types: [
      FETCH_MEMORIES_PHOTOS_REQUEST,
      {
        type: FETCH_MEMORIES_PHOTOS_SUCCESS,
        payload: (action, state, res) => res.json(),
      },
      {
        type: FETCH_MEMORIES_PHOTOS_FAILURE,
        meta: (action) => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const fetchPhoto = (id) => ({
  [RSAA]: {
    endpoint: `https://krassicalendar.com/api/photos/${id}/`,
    headers: {
      "x-auth-token": localStorage.token,
    },
    method: "GET",
    types: [
      FETCH_PHOTO_REQUEST,
      {
        type: FETCH_PHOTO_SUCCESS,
        payload: (action, state, res) => res.blob(),
        meta: id,
      },
      {
        type: FETCH_PHOTO_FAILURE,
        meta: (action) => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const memoriesMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case FETCH_MEMORIES_PHOTOS_SUCCESS:
        dispatch(setMemoriesPhotoIds(action.payload));
        break;

      case FETCH_MEMORIES_PHOTOS_FAILURE:
        break;

      case FETCH_PHOTO_SUCCESS:
        const url = window.URL.createObjectURL(action.payload);
        dispatch(setMemoriesPhotos(url, action.meta));
        break;

      case FETCH_PHOTO_FAILURE:
        break;

      default:
        break;
    }
  };
