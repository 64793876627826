import { RSAA } from "redux-api-middleware";
import { setCalendar } from "./reducers/calendarReducer";
import { setDownload } from "./reducers/calendarReducer";

export const FETCH_CALENDAR_REQUEST = "FETCH_CALENDAR_REQUEST";
export const FETCH_CALENDAR_SUCCESS = "FETCH_CALENDAR_SUCCESS";
export const FETCH_CALENDAR_FAILURE = "FETCH_CALENDAR_FAILURE";

export const DOWNLOAD_CALENDAR_REQUEST = "DOWNLOAD_CALENDAR_REQUEST";
export const DOWNLOAD_CALENDAR_SUCCESS = "DOWNLOAD_CALENDAR_SUCCESS";
export const DOWNLOAD_CALENDAR_FAILURE = "DOWNLOAD_CALENDAR_FAILURE";

export const fetchCalendar = (year, age) => ({
  [RSAA]: {
    endpoint: `https://krassicalendar.com/api/events/${year}/${age}/`,
    headers: {
      "x-auth-token": localStorage.token,
    },
    method: "GET",
    types: [
      FETCH_CALENDAR_REQUEST,
      {
        type: FETCH_CALENDAR_SUCCESS,
        payload: (action, state, res) => res.json(),
      },
      {
        type: FETCH_CALENDAR_FAILURE,
        meta: (action) => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const downloadCalendar = (year, age) => ({
  [RSAA]: {
    endpoint: `https://krassicalendar.com/api/home/download/${year}/${age}/`,
    headers: {
      "x-auth-token": localStorage.token,
    },
    method: "GET",
    types: [
      DOWNLOAD_CALENDAR_REQUEST,
      {
        type: DOWNLOAD_CALENDAR_SUCCESS,
        payload: (action, state, res) => res.blob(),
      },
      {
        type: DOWNLOAD_CALENDAR_FAILURE,
        meta: (action) => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const calendarMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case FETCH_CALENDAR_SUCCESS:
        dispatch(setCalendar(action.payload));
        break;

      case FETCH_CALENDAR_FAILURE:
        break;

      case DOWNLOAD_CALENDAR_SUCCESS:
        const url = window.URL.createObjectURL(action.payload);
        dispatch(setDownload(url));
        break;

      case DOWNLOAD_CALENDAR_FAILURE:
        break;

      default:
        break;
    }
  };
