import React, { useEffect, useState } from 'react';
import get from 'lodash/get';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';

import { useSelector } from 'react-redux';
import withWidth from '@material-ui/core/withWidth';

const useStyles = makeStyles((theme) => ({
  img: {
    maxWidth: '100%',
    height: 'auto',
    boxShadow: '5px 5px 5px #ccc',
    webkitBoxShadow: '5px 5px 5px #ccc',
    mozBoxShadow: '5px 5px 5px #ccc'
  },
  zoomImg: {
    height: '70vh',
    width: 'auto'
  }
}));


// The example data is structured as follows:
const images = [{
    img: "tempPhotos/IMG_0001.HEIC",
    title: 'Image',
    author: 'author',
    cols: 1,
  },
  {
    img: "tempPhotos/IMG_0002.JPG",
    title: 'Image',
    author: 'author',
    cols: 1,
  },
  {
    img: "tempPhotos/IMG_0003.JPG",
    title: 'Image',
    author: 'author',
    cols: 2,
  },
  {
    img: "tempPhotos/IMG_0005.JPG",
    title: 'Image',
    author: 'author',
    cols: 2,
  },
  {
    img: "tempPhotos/IMG_0008.JPG",
    title: 'Image',
    author: 'author',
    cols: 2,
  },
  {
    img: "tempPhotos/IMG_0007.JPG",
    title: 'Image',
    author: 'author',
    cols: 2,
  },
  {
    img: "tempPhotos/IMG_0010.JPG",
    title: 'Image',
    author: 'author',
    cols: 1,
  },
  {
    img: "tempPhotos/IMG_0011.jpg",
    title: 'Image',
    author: 'author',
    cols: 2,
  },
  {
    img: "tempPhotos/IMG_0012.JPG",
    title: 'Image',
    author: 'author',
    cols: 1,
  },
  {
    img: "tempPhotos/IMG_0004.JPG",
    title: 'Image',
    author: 'author',
    cols: 2,
  },
  {
    img: "tempPhotos/IMG_0014.JPG",
    title: 'Image',
    author: 'author',
    cols: 1,
  },
  {
    img: "tempPhotos/IMG_0015.jpg",
    title: 'Image',
    author: 'author',
    cols: 1,
  },
  {
    img: "tempPhotos/IMG_0016.jpeg",
    title: 'Image',
    author: 'author',
    cols: 1,
  },
  {
    img: "tempPhotos/IMG_0017.jpg",
    title: 'Image',
    author: 'author',
    cols: 1,
  },
];

const ImageGrid = ({ spacing, photoIds, width }) => {
  const classes = useStyles();
  const photosIdsById = photoIds.map(e => e._id);
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [nextValue, setNextValue] = useState('');
  const [previousValue, setPreviousValue] = useState('');

  const memoriesPhotos = useSelector(state => get(state, `memoriesReducer.photos`, []));

  const handleClickOpen = (event) => {
    setOpen(true);
    updateSelection(event.currentTarget.id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateSelection = (selection) => {
    setSelectedValue(memoriesPhotos[selection])
    const index = photosIdsById.indexOf(selection) + (photosIdsById.length * 100);
    if(index % 3 === 0) {
      setNextValue(photosIdsById[(index + 2) % photosIdsById.length])
      setPreviousValue(photosIdsById[(index - 2) % photosIdsById.length])
    } if(index % 3 === 1) {
      setNextValue(photosIdsById[(index + 2) % photosIdsById.length])
      setPreviousValue(photosIdsById[(index + 1) % photosIdsById.length])
    } if(index % 3 === 2) {
      setNextValue(photosIdsById[(index - 1) % photosIdsById.length])
      setPreviousValue(photosIdsById[(index - 2) % photosIdsById.length])
    }
  }

  useEffect(() => {
    console.log(width)
  }, [width])

  useEffect(() => {
    const handleUserKeyPress = event => {
      const { keyCode } = event;
      if (keyCode === 39 ) {
        updateSelection(nextValue);
      }
      if (keyCode === 37 ) {
        updateSelection(previousValue);
      }
    };

    const updateSelection = (selection) => {
      setSelectedValue(memoriesPhotos[selection])
      const index = photosIdsById.indexOf(selection) + (photosIdsById.length * 100);
      if(index % 3 === 0) {
        setNextValue(photosIdsById[(index + 2) % photosIdsById.length])
        setPreviousValue(photosIdsById[(index - 2) % photosIdsById.length])
      } if(index % 3 === 1) {
        setNextValue(photosIdsById[(index + 2) % photosIdsById.length])
        setPreviousValue(photosIdsById[(index + 1) % photosIdsById.length])
      } if(index % 3 === 2) {
        setNextValue(photosIdsById[(index - 1) % photosIdsById.length])
        setPreviousValue(photosIdsById[(index - 2) % photosIdsById.length])
      }
    }

    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [open, selectedValue, nextValue, previousValue, photosIdsById, memoriesPhotos]);

  const getImgCol = col => (
    <Grid container direction='column' spacing={spacing}>
      { photoIds.filter((photo, ind) =>{
          if (ind % 3 === col ){
            return true
          } else {
            return false
          }
      }).map((photo, index) => (
        <Grid item key={`${col}-${index}`}>
          <img alt='' id={photo._id} src={memoriesPhotos[photo._id]} className={classes.img} onClick={handleClickOpen} />
        </Grid>
      ))}
    </Grid>
  );

  return (
    <div>
      <Grid container direction='row' spacing={spacing}>
        <Grid item xs={4}>
          {getImgCol(0)}
        </Grid>
        <Grid item xs={4}>
          {getImgCol(2)}
        </Grid>
        <Grid item xs={4}>
          {getImgCol(1)}
        </Grid>
      </Grid>
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth={false}>
        <img alt= '' src={selectedValue} className={['md','lg','xl'].includes(width) ? classes.zoomImg : classes.img }/>
      </Dialog>
    </div>
  );
}

export default withWidth()(ImageGrid);