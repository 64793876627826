import React from "react";
import { useState, useEffect } from "react";
import get from "lodash/get";

// Material UI
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

// Components
import MyCalendar from "../components/MyCalendar";
import CalendarListView from "../components/CalendarListView";
import TwoWordSwitch from "../components/TwoWordSwitch";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { setView, setAge, setYear } from "../redux/reducers/calendarReducer";
import { fetchCalendar, downloadCalendar } from "../redux/calendar";
import { fetchHomePhoto, fetchHomeData } from "../redux/home";

const useStyles = makeStyles((theme) => ({
  image: {
    width: "100%",
    height: "auto",
    boxShadow: "5px 5px 5px #ccc",
    webkitBoxShadow: "5px 5px 5px #ccc",
    mozBoxShadow: "5px 5px 5px #ccc",
  },
  welcomeText: {
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  navigationButton: {
    marginTop: "20px",
    marginBottom: "20px",
    "&:hover": {
      background: "none",
    },
  },
  exitButton: {
    padding: "4px",
    "&:hover": {
      background: "none",
    },
    minWidth: "5px",
    maxWidth: "5px",
  },
  buttonRow: {
    minHeight: "150px",
    zIndex: 10000,
  },
  calendarRow: {
    width: "100%",
    backgroundImage: `url('https://images.unsplash.com/photo-1482242248426-c4e9fe3a559a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80')`,
    minHeight: "1000px",
    backgroundPosition: "10% 0%",
    transform: "scaleX(-1)",
  },
  calendarArea: {
    width: "80%",
    marginRight: "auto",
    marginLeft: "auto",
    paddingBottom: "100px",
    transform: "scaleX(-1)",
  },
  listArea: {
    width: "95%",
    marginRight: "auto",
    marginLeft: "auto",
    transform: "scaleX(-1)",
  },
  calendarBackground: {
    background: "white",
  },
  calendarTitleMobile: {
    fontWeight: "bold",
    padding: "10px",
    textAlign: "center",
    borderBottom: "1px solid rgb(217,217,217)",
    position: "-webkit-sticky" /* Safari */,
    position: "sticky",
    zIndex: 1000,
    top: "99px",
    background: "white",
  },
  calendarTitle: {
    fontWeight: "bold",
    padding: "10px",
    textAlign: "center",
    border: "1px solid rgb(217,217,217)",
    position: "-webkit-sticky" /* Safari */,
    position: "sticky",
    zIndex: 1000,
    top: "119px",
    background: "white",
  },
  pictureAndButton: {
    position: "relative",
    minHeight: "852px",
  },
  bottomArea: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "134px",
  },
  printButton: {
    textTransform: "none",
  },
  mobilePreviousYears: {
    paddingTop: "10px",
    paddingBottom: "10px",
    minHeight: "65px",
    maxHeight: "65px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  ageSelection: {
    paddingLeft: "8px",
    background: "#F6F8F7FE",
    borderRadius: "3px",
    maxWidth: "200px",
    minWidth: "200px",
  },
}));

const CalendarArea = (props) => {
  const classes = useStyles();

  const mobileView = props.width === "xs";

  const [downloadClicked, setDownloadClicked] = useState(false);
  const [viewPreviousYears, setViewPreviousYears] = useState(false);

  const myEventsList = useSelector((state) =>
    get(state, `calendarReducer.calendarData`, [])
  );
  const calendarView = useSelector((state) =>
    get(state, `calendarReducer.calendarView`, true)
  );
  const downloadUrl = useSelector((state) =>
    get(state, `calendarReducer.downloadUrl`, "")
  );
  const calendarAge = useSelector((state) =>
    get(state, `calendarReducer.calendarAge`, `adult`)
  );
  const calendarYear = useSelector((state) =>
    get(state, `calendarReducer.calendarYear`, 2022)
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCalendar(calendarYear, calendarAge));
    dispatch(fetchHomePhoto());
    dispatch(fetchHomeData());
  }, [dispatch, calendarYear, calendarAge]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (downloadClicked && downloadUrl !== "") {
      window.open(downloadUrl);
    }
  }, [downloadUrl, downloadClicked]);

  const handleViewChange = () => {
    dispatch(setView({ calendarView: !calendarView }));
  };

  const handleAgeChange = () => {
    const age = calendarAge === "adult" ? "kid" : "adult";
    dispatch(setAge({ calendarAge: age }));
  };

  const handleYearChange = (event) => {
    console.log(event.target.value);
    dispatch(setYear({ calendarYear: event.target.value }));
  };

  const handleDownload = () => {
    setDownloadClicked(true);
    dispatch(downloadCalendar(calendarYear, calendarAge));
  };

  return (
    <div className={classes.calendarRow}>
      <div className={mobileView ? classes.listArea : classes.calendarArea}>
        {mobileView ? (
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.mobilePreviousYears}
          >
            <Grid item xs={7}>
              <Grid
                container
                justify="flex-start"
                alignItems="center"
                className={classes.ageSelection}
              >
                <TwoWordSwitch
                  firstLabel="Adult"
                  secondLabel="Children"
                  onChange={handleAgeChange}
                />
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Grid container justify="flex-end" alignItems="center">
                {viewPreviousYears ? (
                  <div>
                    <Button
                      disableRipple
                      className={classes.exitButton}
                      fullWidth={false}
                      onClick={() => setViewPreviousYears(false)}
                    >
                      <Typography variant={"body2"}>x</Typography>
                    </Button>
                    <FormControl className={classes.formControl} size="small">
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={calendarYear}
                        onChange={handleYearChange}
                      >
                        <MenuItem value={2020}>2020</MenuItem>
                        <MenuItem value={2021}>2021</MenuItem>
                        <MenuItem value={2022}>2022</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                ) : (
                  <Button
                    disableRipple
                    onClick={() => setViewPreviousYears(true)}
                  >
                    <Typography variant={"body2"}>Year</Typography>
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid
            style={{ padding: "20px" }}
            container
            justify="center"
            alignItems="center"
          >
            <Grid item xs={3}>
              <Grid
                container
                justify="flex-start"
                alignItems="center"
                className={classes.ageSelection}
              >
                <TwoWordSwitch
                  firstLabel="Adult"
                  secondLabel="Children"
                  onChange={handleAgeChange}
                />
              </Grid>
            </Grid>
            <Grid container item xs={6} justify="center">
              <Button
                className={classes.printButton}
                onClick={handleDownload}
                variant="text"
                disableRipple
              >
                <Typography variant="h5" align="center" color="error">
                  Download the Printable PDF Version
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Grid container justify="flex-end" alignItems="center">
                <TwoWordSwitch
                  firstLabel="Calendar"
                  secondLabel="List View"
                  onChange={handleViewChange}
                  checked={!calendarView}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        {calendarView && !mobileView ? (
          <div className={classes.calendarBackground}>
            <Grid
              container
              className={classes.calendarTitle}
              alignItems="center"
            >
              <Grid item lg={3} />
              <Grid item xs={9} lg={6}>
                <div>
                  <Typography variant="h2">December {calendarYear}</Typography>
                  {calendarAge === "kid" ? (
                    <Typography variant={"body2"} style={{ color: "white" }}>
                      ~~~
                    </Typography>
                  ) : (
                    <Typography variant={"body2"}>
                      Click each day for full event details
                    </Typography>
                  )}
                </div>
              </Grid>
              <Grid item xs={3}>
                {viewPreviousYears ? (
                  <div>
                    <Button
                      disableRipple
                      className={classes.exitButton}
                      fullWidth={false}
                      onClick={() => setViewPreviousYears(false)}
                    >
                      <Typography variant={"body2"}>x</Typography>
                    </Button>
                    <FormControl className={classes.formControl}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={calendarYear}
                        onChange={handleYearChange}
                      >
                        <MenuItem value={2020}>2020</MenuItem>
                        <MenuItem value={2021}>2021</MenuItem>
                        <MenuItem value={2022}>2022</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                ) : (
                  <Button
                    disableRipple
                    onClick={() => setViewPreviousYears(true)}
                  >
                    <Typography variant={"body2"}>
                      View Previous Years
                    </Typography>
                  </Button>
                )}
              </Grid>
            </Grid>
            <MyCalendar events={myEventsList} />
          </div>
        ) : (
          <div>
            <Grid
              container
              className={
                mobileView ? classes.calendarTitleMobile : classes.calendarTitle
              }
              alignItems="center"
            >
              <Grid item lg={3} />
              <Grid item xs={12} lg={6}>
                <div>
                  <Typography variant="h2">December {calendarYear}</Typography>
                  {calendarAge === "kid" ? (
                    <Typography variant={"body2"} style={{ color: "white" }}>
                      ~~~
                    </Typography>
                  ) : (
                    <Typography variant={"body2"}>
                      Click each day for full event details
                    </Typography>
                  )}
                </div>
              </Grid>
              {!mobileView && (
                <Grid item xs={3}>
                  {viewPreviousYears ? (
                    <div>
                      <Button
                        disableRipple
                        className={classes.exitButton}
                        fullWidth={false}
                        onClick={() => setViewPreviousYears(false)}
                      >
                        <Typography variant={"body2"}>x</Typography>
                      </Button>
                      <FormControl className={classes.formControl}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={calendarYear}
                          onChange={handleYearChange}
                        >
                          <MenuItem value={2020}>2020</MenuItem>
                          <MenuItem value={2021}>2021</MenuItem>
                          <MenuItem value={2022}>2022</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  ) : (
                    <Button
                      disableRipple
                      onClick={() => setViewPreviousYears(true)}
                    >
                      <Typography variant={"body2"}>
                        View Previous Years
                      </Typography>
                    </Button>
                  )}
                </Grid>
              )}
            </Grid>
            <CalendarListView events={myEventsList} year={calendarYear} />
          </div>
        )}
      </div>
    </div>
  );
};

export default withWidth()(CalendarArea);
