import { combineReducers } from 'redux';
import { authReducer } from './authReducer';
import { calendarReducer } from './calendarReducer';
import { homeReducer } from './homeReducer';
import { aboutReducer } from './aboutReducer';
import { memoriesReducer } from './memoriesReducer';
import loadingReducer from './loadingReducer';

const rootReducer = combineReducers({
  authReducer,
  calendarReducer,
  loadingReducer,
  homeReducer,
  aboutReducer,
  memoriesReducer
});

export default rootReducer;