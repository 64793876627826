import React from 'react';
import { Link } from 'react-router-dom';

import Drawer from './TempDrawer';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';


const useStyles = makeStyles((theme) => ({
  headerGrid: {
    paddingTop: '2rem',
    paddingBottom: '2rem',
    width: '100vw',
    fontSize: '40px',
    color: 'red',
    textAlign: 'center',
  },
  headerButtons: {
    color: 'white',
    background: 'rgb(113, 113, 113, .80)',
    height: '52px',
    width: '12rem',
    textTransform: 'none',    
  },
  stickyClear:{
    position: "-webkit-sticky", /* Safari */
    position: "sticky",
    top: 0,
    zIndex: 1000
  },
  stickyBackground:{
    position: "-webkit-sticky", /* Safari */
    position: "sticky",
    top: 0,
    background: `url('https://images.unsplash.com/photo-1519248708452-2c393f8dc725?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80')`,
    zIndex: 1000
  },
  smallHeight: {
    minHeight: '99px',
    backgroundPosition: '0% 61%',
  },
  largeHeight: {
    minHeight: '119px',
    backgroundPosition: '0% 62%',
  },
  link: {
    textDecoration: 'none' 
  },
  buttonText: {
    fontFamily: 'Montserrat',
  }
}));

const Header = ( props ) => {
  const classes = useStyles();

  const buttonGrid = () => (
    <Grid
          container
          alignItems='center'
          justify='space-evenly'
          className={classes.headerGrid}
        >
      {['Home', 'About', 'Calendar', 'Memories', 'Guestbook'].map(text =>
        (
        <Grid item lg={2} key={text}>
          <Link to={`/${text.toLowerCase()}`} className={classes.link}>
            <Button className={classes.headerButtons}>
              <Typography variant='body1' className={classes.buttonText}>
                {text}
              </Typography>
            </Button>
          </Link>
        </Grid>
        )
      )}
    </Grid>
  );

  return (
    <div className={
       `${props.background ? classes.stickyBackground : classes.stickyClear} 
        ${props.width === 'xs' || props.width === 'sm' ? classes.smallHeight : classes.largeHeight}` }>
      <Hidden mdDown>
        {buttonGrid()}
      </Hidden>
      <Hidden lgUp>
        <Drawer />
      </Hidden>
    </div>
  );
};

export default withWidth()(Header);
