import React from "react";
import { useState, useEffect } from "react";
import get from "lodash/get";
import { useSelector } from "react-redux";

import { Calendar, momentLocalizer } from "react-big-calendar";
import Event from "./Event";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

const MyCalendar = ({ events }) => {
  const calendarYear = useSelector((state) =>
    get(state, `calendarReducer.calendarYear`, 2022)
  );

  return (
    <Calendar
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      defaultView="month"
      toolbar={false}
      views={["month"]}
      date={new Date(calendarYear, 11, 1)}
      style={{ height: 800 }}
      components={{
        eventWrapper: Event,
      }}
      getNow={() => new Date(calendarYear - 1, 11, 1)}
    />
  );
};

export default MyCalendar;
