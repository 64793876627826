import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import get from "lodash/get";

// Material UI
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

// Components
import Header from "../components/Header";

// Utils
import nth from "../utils/nth";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { fetchCalendar } from "../redux/calendar";

const useStyles = makeStyles((theme) => ({
  dayTitle: {
    paddingBottom: "5px",
    paddingRight: "20px",
    paddingLeft: "20px",
    paddingTop: "20px",
  },
  day: {
    paddingBottom: "25px",
  },
  dayContent: {
    paddingTop: "10px",
    paddingBottom: "35px",
    paddingRight: "20px",
    paddingLeft: "20px",
    minHeight: "250px",
  },
  printButton: {
    textTransform: "none",
  },
  superScript: {
    height: "100%",
    verticalAlign: "super",
    fontSize: "80%",
    marginRight: "8px",
  },
  paperLarge: {
    marginTop: "25px",
    width: "80%",
    marginRight: "auto",
    marginLeft: "auto",
  },
  paperSmall: {
    marginTop: "10px",
    width: "95%",
    marginRight: "auto",
    marginLeft: "auto",
  },
  backdrop: {
    backgroundImage: `url('https://images.unsplash.com/photo-1508172819040-536e3f54970f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80')`,
    minHeight: "1000px",
    backgroundPosition: "0% 75.8%",
  },
  button: {
    textAlign: "center",
    backgroundColor: "#FFFFFF",
    textTransform: "none",
    padding: "20px",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
  returnButtonSmall: {
    textAlign: "center",
    background: "white",
    textTransform: "none",
    minHeight: "64px",
    padding: "20px",
  },
  returnButtonLarge: {
    textAlign: "center",
    background: "white",
    textTransform: "none",
    padding: "20px",
    minHeight: "64px",
    minWidth: "300px",
  },
  link: {
    textDecoration: "none",
    width: "100%",
  },
  hyperLink: {
    wordWrap: "break-word",
  },
}));

const Day = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [size, setSize] = useState(props.width);

  const myEventsList = useSelector((state) =>
    get(state, `calendarReducer.calendarData`, [])
  );

  const calendarYear = useSelector((state) =>
    get(state, `calendarReducer.calendarYear`, 2022)
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.match.params.day]);

  useEffect(() => {
    setSize(props.width);
  }, [props.width]);

  useEffect(() => {
    dispatch(fetchCalendar());
  }, [dispatch]);

  const populateMultiLineText = (text) => {
    const splitText = text.split("<br><br>");
    return splitText.map((t, i) => {
      return (
        <Typography variant="body1" paragraph={true} key={i}>
          {t.split("$$").map((l, j) => {
            return j % 2 === 0 ? (
              l
            ) : (
              <a
                className={classes.hyperLink}
                href={l}
                rel="noopener noreferrer"
                target="_blank"
                key={`${i}-${j}`}
              >
                {l}
              </a>
            );
          })}
        </Typography>
      );
    });
  };

  return (
    <div className={classes.backdrop}>
      <Header background={true} />
      <Paper
        className={
          ["xs", "sm", "md"].includes(size)
            ? classes.paperSmall
            : classes.paperLarge
        }
      >
        <Grid container justify="center" alignItems="center">
          <Grid item md={11} className={classes.dayTitle}>
            <Typography
              variant={size === "xs" || size === "xs" ? "h4" : "h2"}
              align="center"
              color="error"
            >
              {myEventsList.length > 0 &&
                myEventsList[props.match.params.day - 1].title.replace(".", "")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={11} className={classes.day}>
            <Grid container justify="center">
              <Grid item>
                <Typography
                  variant={size === "xs" || size === "xs" ? "h6" : "h4"}
                  align="center"
                  color={"secondary"}
                  display="inline"
                >
                  December {props.match.params.day}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  className={classes.superScript}
                  variant={size === "xs" || size === "xs" ? "h6" : "h4"}
                  align={"center"}
                  color={"secondary"}
                  display="inline"
                >
                  {nth(props.match.params.day)}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant={size === "xs" || size === "xs" ? "h6" : "h4"}
                  align={"center"}
                  color={"secondary"}
                  display="inline"
                >
                  {`${props.match.params.year}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={10} className={classes.dayContent}>
            {myEventsList.length > 0 &&
              populateMultiLineText(
                myEventsList[props.match.params.day - 1].longDescription
              )}
          </Grid>
        </Grid>
      </Paper>
      <Grid
        container
        className={
          ["xs", "sm", "md"].includes(size)
            ? classes.paperSmall
            : classes.paperLarge
        }
        justify="space-between"
      >
        <Grid item lg={3} md={2}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100px"
            marginTop="35px"
          >
            {parseInt(props.match.params.day) - 1 !== 0 && (
              <Link
                to={`/day/${
                  parseInt(props.match.params.day) - 1
                }/${calendarYear}`}
                className={classes.link}
              >
                <Button className={classes.button} fullWidth disableRipple>
                  <ArrowBackIosIcon />
                  <Hidden mdDown>
                    <Typography
                      variant="body2"
                      display="inline"
                      color="secondary"
                    >
                      {`December ${parseInt(props.match.params.day) - 1}`}
                    </Typography>
                    <Typography
                      className={classes.superScript}
                      variant={"body2"}
                      align={"center"}
                      color={"secondary"}
                      display="inline"
                    >
                      {nth(parseInt(props.match.params.day) - 1)}
                    </Typography>
                  </Hidden>
                </Button>
              </Link>
            )}
          </Box>
        </Grid>
        <Grid item xs={6} md={4}>
          <Link to={`/calendar`} className={classes.link}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="100px"
              marginTop="35px"
            >
              <Button
                className={
                  size === "xs"
                    ? classes.returnButtonSmall
                    : classes.returnButtonLarge
                }
                disableRipple
              >
                <Typography variant="body2" display="inline" color="secondary">
                  Return to Calendar
                </Typography>
              </Button>
            </Box>
          </Link>
        </Grid>
        <Grid item lg={3} md={2}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100px"
            marginTop="35px"
          >
            {parseInt(props.match.params.day) + 1 !== 26 && (
              <Link
                to={`/day/${
                  parseInt(props.match.params.day) + 1
                }/${calendarYear}`}
                className={classes.link}
              >
                <Button className={classes.button} fullWidth disableRipple>
                  <Hidden mdDown>
                    <Typography
                      variant="body2"
                      display="inline"
                      color="secondary"
                    >
                      {`December ${parseInt(props.match.params.day) + 1}`}
                    </Typography>
                    <Typography
                      className={classes.superScript}
                      variant={"body2"}
                      align={"center"}
                      color={"secondary"}
                      display="inline"
                    >
                      {nth(parseInt(props.match.params.day) + 1)}
                    </Typography>
                  </Hidden>
                  <ArrowForwardIosIcon />
                </Button>
              </Link>
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default withWidth()(Day);
