import React, { useState } from "react";
import { Redirect } from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { generalLogin } from "../redux/auth";
import { get } from "lodash";

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    height: "100vh",
    backgroundImage: `url('https://images.unsplash.com/photo-1519248708452-2c393f8dc725?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80')`,
    position: "relative",
  },
  innerContainerLarge: {
    width: "675px",
    minHeight: "400px",
    margin: 0,
    position: "absolute",
    top: "50%",
    left: "50%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },
  innerContainerSmall: {
    width: "90%",
    minHeight: "400px",
    margin: 0,
    position: "absolute",
    top: "50%",
    left: "50%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },
  loginPaper: {
    padding: "20px",
    backgroundColor: "rgba(255,255,255, .9)" /* Black w/opacity/see-through */,
  },
  errorPaper: {
    marginTop: "20px",
    padding: "10px",
    backgroundColor: "rgba(255,255,255, .9)" /* Black w/opacity/see-through */,
  },
  loginTitle: {
    width: "100%",
    fontSize: "40px",
    color: "red",
    textAlign: "center",
  },
  loginItem: {
    minHeight: "50px",
  },
  loginSubtitle: {
    paddingRight: "10px",
    fontSize: "20px",
    textAlign: "center",
    verticalAlign: "middle",
    minHeight: "100%",
  },
  loginButton: {
    "&:hover": {
      background: "green",
      boxShadow: "inset 0 0 0 2em var(--hover)",
    },
    "&:focus": {
      background: "green",
      boxShadow: "inset 0 0 0 2em var(--hover)",
    },
  },
  tf: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
}));

const Login = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [password, setPassword] = useState("");
  const mobileView = props.width === "xs" || props.width === "sm";

  const generalAuthentication = useSelector(
    (state) => state.authReducer.generalAuthentication
  );
  const errors = useSelector((state) => get(state, "authReducer.errors", []));
  const logInLoading = useSelector(
    (state) => state.loadingReducer.GENERAL_LOGIN
  );

  const handleClick = () => {
    dispatch(generalLogin(password));
  };

  const handleChange = (e) => {
    setPassword(e.currentTarget.value);
  };

  if (generalAuthentication) {
    return <Redirect to="/home" />;
  }

  return (
    <div className={classes.outerContainer}>
      <div
        className={
          mobileView ? classes.innerContainerSmall : classes.innerContainerLarge
        }
      >
        <Paper elevation={0} className={classes.loginPaper}>
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.loginGrid}
          >
            <Grid item xs={12} className={classes.loginTitle}>
              Krassi's Christmas Calendar 2022{" "}
            </Grid>
            <Grid item xs={12} style={{ minHeight: "20px" }} />
            <Grid
              item
              container
              xs={12}
              sm={6}
              alignItems="center"
              justify="center"
              style={{ minHeight: "50px" }}
            >
              <Typography
                display="inline"
                className={classes.loginSubtitle}
                color="secondary"
              >
                Enter the password to continue:
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={8}
              sm={4}
              alignItems="center"
              justify="center"
              style={{ minHeight: "50px" }}
            >
              <TextField
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleClick();
                  }
                }}
                id="outlined-password-input"
                type="password"
                label="Password"
                variant="outlined"
                InputProps={{ disableUnderline: true }}
                style={{ paddingRight: "10px" }}
                color="secondary"
                value={password}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              container
              xs={4}
              sm={2}
              alignItems="center"
              justify="center"
              style={{ minHeight: "50px" }}
            >
              {logInLoading ? (
                <CircularProgress />
              ) : (
                <Button
                  onClick={handleClick}
                  className={classes.loginButton}
                  disableRipple
                >
                  <img
                    alt="login submit"
                    style={{ width: "100px" }}
                    src={window.location.origin + "/arrow.png"}
                  />
                </Button>
              )}
            </Grid>
          </Grid>
        </Paper>
        {errors.length > 0 && !logInLoading && (
          <Paper elevation={0} className={classes.errorPaper}>
            <Grid item xs={12}>
              <Grid container justify="center" alignItems="center">
                <Typography
                  className={classes.loginSubtitle}
                  display="inline"
                  align="center"
                  color="error"
                >
                  {errors[0].msg} - Please Try Again
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        )}
      </div>
    </div>
  );
};

export default withWidth()(Login);
