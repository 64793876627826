export const SET_MEMORIES_PHOTO_IDS = 'SET_MEMORIES_PHOTO_IDS';
export const SET_MEMORIES_PHOTOS = 'SET_MEMORIES_PHOTOS';

const initialState = {
  photoIds: [],
  photos: {},
};

export const setMemoriesPhotoIds = photos => {
  return { type: SET_MEMORIES_PHOTO_IDS, photos };
};

export const setMemoriesPhotos = (photoUrl, id) => {
  return { type: SET_MEMORIES_PHOTOS, payload: { photoUrl, id } };
};

export function memoriesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_MEMORIES_PHOTO_IDS:
      return {
        ...state,
        photoIds: action.photos,
      };
    
    case SET_MEMORIES_PHOTOS: 
      return {
        ...state,
        photos: {
          ...state.photos,
          [action.payload.id]: action.payload.photoUrl
        }
      }

    default:
      return state;
  }
}


