import React, { useEffect, useState } from 'react';

// Components
import Header from '../components/Header';

// Material UI
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

const useStyles = makeStyles((theme) => ({
  aboutGrid: {
    marginTop: '20px',
  },
  aboutContent: {
    marginRight: '20px',
    marginLeft: '20px',
    padding: '30px'
  },
  paperLarge: {
    marginTop: '30px',
    width: '80%',
    marginRight: 'auto',
    marginLeft: 'auto'
  },
  paperSmall: {
    marginTop: '30px',
    width: '95%',
    marginRight: 'auto',
    marginLeft: 'auto'
  },
  backdrop: {
    backgroundImage: `url('https://images.unsplash.com/photo-1508172819040-536e3f54970f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80')`,
    minHeight: '1000px',
    backgroundPosition: '0% 75.8%',
  },
}));

const Guestbook = (props) => {
  const classes = useStyles();
  const [size, setSize] = useState(props.width);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  useEffect(() => {
    setSize(props.width)
  },[props.width])

  return (
    <div className={classes.backdrop}>
      <Header background={true}/>
      <Paper className={['xs', 'sm'].includes(size) ? classes.paperSmall : classes.paperLarge }>
        <Grid container justify="center" className={classes.aboutGrid}>
          <Grid item xs={12} md={10} className={classes.aboutContent}>
            <Typography variant='h2' align='center' color='error' paragraph={true}>
              Guestbook Coming Soon!
            </Typography>
            <Typography variant='body1' align='center'>
              While the guest book is in progress, please email us at <a href="mailto:krassicalendar@gmail.com">krassicalendar@gmail.com</a> to tell us about your and your family's holiday adventures using the calendar, and your memories with Krassi!
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default withWidth()(Guestbook);
