import React, { useEffect, useState } from 'react';
import get from 'lodash/get';

// Components
import Header from '../components/Header';
import ImageGrid from '../components/ImageGrid'

// Material UI
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { fetchMemoriesPhotos } from '../redux/memories';
import { fetchPhoto } from '../redux/memories';

const useStyles = makeStyles((theme) => ({
  aboutGrid: {
    marginTop: '20px',
  },
  aboutContent: {
    marginRight: '20px',
    marginLeft: '20px',
    padding: '30px'
  },
  paperLarge: {
    marginTop: '30px',
    width: '80%',
    marginRight: 'auto',
    marginLeft: 'auto'
  },
  paperSmall: {
    marginTop: '30px',
    width: '95%',
    marginRight: 'auto',
    marginLeft: 'auto'
  },
  backdrop: {
    background: 'linear-gradient(90deg, rgba(226,226,226,1) 0%, rgba(244,244,244,1) 50%, rgba(223,223,223,1) 100%)',
    minHeight: '1000px',
    backgroundPosition: '0% 75.8%',
  },
  photosSmall: {
    marginTop: '30px',
    width: '95%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  photosLarge: {
    marginTop: '30px',
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
  }
}));

const Memories = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [size, setSize] = useState(props.width);

  const memoriesPhotoIds = useSelector(state => get(state, `memoriesReducer.photoIds`, []));

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  useEffect(() => {
    setSize(props.width)
  },[props.width])
  
  useEffect(() => {
    dispatch(fetchMemoriesPhotos())
  }, [dispatch])

  useEffect(() => {
    if (memoriesPhotoIds.length > 0 ) {
      memoriesPhotoIds.forEach(photo => {
        dispatch(fetchPhoto(photo._id))
      })
    }
  }, [dispatch, memoriesPhotoIds])

  return (
    <div className={classes.backdrop}>
      <Header background={true}/>
      <Paper className={['xs', 'sm'].includes(size) ? classes.paperSmall : classes.paperLarge }>
        <Grid container justify="center" className={classes.aboutGrid}>
          <Grid item xs={12} md={10} className={classes.aboutContent}>
            <Typography variant="h2" align='center' color='error'>
              Memories
            </Typography> 
          </Grid>
        </Grid>
      </Paper>
      { memoriesPhotoIds.length > 0 && 
        <div className={['xs', 'sm'].includes(size) ? classes.photosSmall : classes.photosLarge }>
          <ImageGrid spacing={['xs', 'sm'].includes(size) ? 1 : 3} photoIds={memoriesPhotoIds} />
        </div>
      }
    </div>
  );
};

export default withWidth()(Memories);
