import { RSAA } from "redux-api-middleware";
import { setAboutData } from "./reducers/aboutReducer";

export const FETCH_ABOUT_REQUEST = "FETCH_ABOUT_REQUEST";
export const FETCH_ABOUT_SUCCESS = "FETCH_ABOUT_SUCCESS";
export const FETCH_ABOUT_FAILURE = "FETCH_ABOUT_FAILURE";

export const fetchAboutData = () => ({
  [RSAA]: {
    endpoint: `https://krassicalendar.com/api/about/`,
    headers: {
      "x-auth-token": localStorage.token,
    },
    method: "GET",
    types: [
      FETCH_ABOUT_REQUEST,
      {
        type: FETCH_ABOUT_SUCCESS,
        payload: (action, state, res) => res.json(),
      },
      {
        type: FETCH_ABOUT_FAILURE,
        meta: (action) => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const aboutMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case FETCH_ABOUT_SUCCESS:
        dispatch(setAboutData(action.payload));
        break;

      case FETCH_ABOUT_FAILURE:
        break;

      default:
        break;
    }
  };
