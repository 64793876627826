import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import get from "lodash/get";

import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import withWidth from "@material-ui/core/withWidth";

import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  calendarArea: {
    background: "white",
    minHeight: "1100px",
  },
  gridItemAdult: {
    marginTop: "20px",
    marginBottom: "20px",
    marginRight: "10px",
    cursor: "pointer",
  },
  gridItemKid: {
    marginTop: "20px",
    marginBottom: "20px",
    marginRight: "10px",
  },
  divider: {
    width: "90%",
  },
  dayButton: {
    textTransform: "none",
    disableRipple: true,
    width: "100%",
  },
  dayText: {
    fontSize: "1.25rem",
  },
}));

const CalendarListView = ({ events, width, year }) => {
  const history = useHistory();
  const classes = useStyles();
  const mobileView = width === "xs";

  const calendarAge = useSelector((state) =>
    get(state, `calendarReducer.calendarAge`, `adult`)
  );

  const clicked = (event) => {
    if (calendarAge === "adult") {
      history.push(`day/${parseInt(event.currentTarget.id) + 1}/${year}`);
    }
  };

  const getEventsGrid = (events) => (
    <Grid container justify="center" className={classes.calendarGrid}>
      {events.map((e, index) => (
        <Fragment key={index}>
          <Grid
            item
            xs={12}
            md={10}
            className={
              calendarAge === "adult"
                ? classes.gridItemAdult
                : classes.gridItemKid
            }
            id={e.id}
            onClick={clicked}
          >
            {populateLine(e)}
          </Grid>
          <Divider className={classes.divider} />
        </Fragment>
      ))}
    </Grid>
  );

  const populateLine = (e) => (
    <Grid container>
      <Grid item xs={3}>
        <Grid
          container
          style={{ height: "100%" }}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Grid item>
            <Typography
              variant={mobileView ? "h5" : "h3"}
              align="center"
              color="error"
            >
              Day
            </Typography>
            <Typography
              variant={mobileView ? "h5" : "h3"}
              align="center"
              color="error"
            >
              {e.id + 1}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={9}>
        <Grid
          container
          style={{ height: "100%" }}
          direction="column"
          justify="center"
        >
          {e.header && (
            <Grid item>
              <Typography
                variant={mobileView ? "body1" : "body1"}
                color="secondary"
                paragraph={true}
              >
                {e.header}
              </Typography>
            </Grid>
          )}
          <Grid item>
            <Typography
              variant="body1"
              className={!mobileView ? classes.dayText : ""}
            >
              {e.title}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return <div className={classes.calendarArea}>{getEventsGrid(events)}</div>;
};

export default withWidth()(CalendarListView);
