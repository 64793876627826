import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  eventHeader: {
    color: "red",
    fontSize: ".75rem",
  },
}));

const Event = (props) => {
  const history = useHistory();
  const classes = useStyles();

  const calendarAge = useSelector((state) =>
    get(state, `calendarReducer.calendarAge`, `adult`)
  );

  const calendarYear = useSelector((state) =>
    get(state, `calendarReducer.calendarYear`, 2022)
  );

  const clicked = (event) => {
    if (calendarAge === "adult") {
      history.push(
        `day/${parseInt(event.currentTarget.id) + 1}/${calendarYear}`
      );
    }
  };

  return (
    <div
      style={{
        padding: "10px",
        height: "150px",
        cursor: calendarAge === "adult" ? "pointer" : "",
      }}
      id={props.event.id}
      onClick={clicked}
    >
      {props.event.header && (
        <Typography
          noWrap={false}
          paragraph={true}
          className={classes.eventHeader}
        >
          {props.event.header}
        </Typography>
      )}
      <Typography noWrap={false} style={{ fontSize: ".75rem" }}>
        {props.event.title}
      </Typography>
    </div>
  );
};

export default Event;
