import { createStore, applyMiddleware } from "redux";

import thunk from "redux-thunk";
import { apiMiddleware } from "redux-api-middleware";
import { calendarMiddleware } from "./calendar";
import { authMiddleware } from "./auth";
import { homeMiddleware } from "./home";
import { aboutMiddleware } from "./about";
import { memoriesMiddleware } from "./memories";

import rootReducer from "./reducers/rootReducer";

const initialState = {};

const middleware = [
  thunk,
  apiMiddleware,
  authMiddleware,
  calendarMiddleware,
  homeMiddleware,
  aboutMiddleware,
  memoriesMiddleware,
];

const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(...middleware)
);

export default store;
