import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import PrivateRoute from './components/routing/PrivateRoute';
import Login from './pages/Login';
import Home from './pages/Home';
import Calendar from './pages/Calendar';
import About from './pages/About';
import Memories from './pages/Memories';
import Guestbook from './pages/Guestbook';
import Day from './pages/Day';

// redux
import { Provider } from 'react-redux'
import store from './redux/store'
import { checkAuth } from './redux/auth'

function App() {

  useEffect(() => {
    store.dispatch(checkAuth());
  }, [])

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path='/login' component={Login} />
          <PrivateRoute exact path='/' component={Home} />
          <PrivateRoute exact path='/home' component={Home} />
          <PrivateRoute exact path='/about' component={About} />
          <PrivateRoute exact path='/calendar' component={Calendar} />
          <PrivateRoute exact path='/memories' component={Memories} />
          <PrivateRoute exact path='/guestbook' component={Guestbook} />
          <PrivateRoute exact path='/day/:day/:year' component={Day} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
 