export const SET_ABOUT_DATA = 'SET_ABOUT_DATA';

const initialState = {
  aboutDescription: '',
};

export const setAboutData = aboutData => {
  return { type: SET_ABOUT_DATA, payload: aboutData };
};

export function aboutReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ABOUT_DATA:
      return {
        ...state,
        aboutDescription: action.payload.aboutDescription,
      };

    default:
      return state;
  }
}